import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ArticleSearch from './ArticleSearch';
import Profile from './Profile';
import Globe from './Globe'
import Login from './Login'

const AppRoutes = () => (
  <Routes>
    <Route path="/articles" element={<ArticleSearch />} />
    <Route path="/profile" element={<Profile />} /> 
  </Routes>
);

export default AppRoutes;