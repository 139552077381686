import React, { useEffect, useState } from "react";
import "./Recommend.css";



function Recommend() { 
 
  const [articles, setArticles] = useState([]);  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

 const items=["Amazon","EC2","S3","Cloud","Lambda"] 

   const pickRandomItem = () => {
     const randomIndex = Math.floor(Math.random() * items.length);
      return items[randomIndex];
 
  }
      useEffect(() => {pickRandomItem();}, []);

  const handleSearch = async () => {
    
  const key= pickRandomItem();
    try {
       const response = await fetch(`https://crawler-microservice-latest.onrender.com/random`);  //https://crawler-microservice-latest.onrender.com
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setArticles(data); 
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

 useEffect(() => {handleSearch();}, []);

  const articleList = [{ id:1,
    title:"google", url:"www.google.com",author:"Unknown"}, { id:2,
        title:"google", url:"www.google.com",author:"Unknown"},
        { id:3,
            title:"render", url:"www.render.com",author:"Unknown"},
            { id:4,
                title:"aws", url:"www.aws.com",author:"Unknown"},
                { id:5,
                    title:"hackernews", url:"www.hackernews.com",author:"Unknown"} ]
  

return (
    <div className="articles-container" >
    <h1 className="articles-heading">Recommended Articles</h1>
    {articles.map((article, index) => (
      <div className="article-horizontal-card" key={index}>
        <div className="article-content">
          <h2 className="article-title">{article.title}</h2>
          <p className="article-author">
            {article.author ? `By ${article.author}` : "Author: Unknown"}
          </p>
        </div>
        <div className="article-action">
          <a
            href={article.url}
            target="_blank"
            rel="noopener noreferrer"
            className="read-more-btn"
          >
            Read More
          </a>
        </div>
      </div>
    ))}
  </div>
  );
};

 

export default Recommend;
