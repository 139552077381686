import logo from './logo.png';
import './App.css';
import NavBar from './NavBar';
import Login from './Login'
import ArticleSearch from './ArticleSearch'
import axios from 'axios';
import Profile from './Profile';
import Globe from './Globe'
import Plot from './Plot'
import Sidebar from './Sidebar'
import { Canvas } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import AppRoutes from './AppRoutes';
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
import { FaGlobe, FaUser, FaNewspaper, FaSignInAlt } from "react-icons/fa";
import CoolNav from './CoolNav'
import Recommend from './Recommend'
import BlogList from './BlogList';
import React, { useEffect, useState } from "react";



 

function App() {  

  // let location = useLocation();
  // const show= location.pathname === "/";
  // const [showComponent, setShowComponent] = useState(true);
  
  // const displayB = () => {
     
  //   if (location.pathname === "/") {
  //     setShowComponent(true);
  //   } else {
  //     setShowComponent(false);
  //   }
  // }
  
  //  useEffect(() => {console.log("bleh");}, []);
 
  return (
    <div>
      <Router>
        <CoolNav /> {/* Pass the handler as a prop */}
        <AppRoutes />  {/* Define main app routes here */}
      </Router>
       
     <Recommend />   
  <div><BlogList /> </div>
    </div>
 
  );
}

export default App;