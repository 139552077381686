import React, { useEffect, useState } from "react";
import "./BlogList.css";

const BlogList = () => {

    const [blogs, setBlogs] = useState([]);  
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
 
    const displayBlogs = async () => {
    try {
       const response = await fetch(`https://crawler-microservice-latest.onrender.com/blogs`);  //https://crawler-microservice-latest.onrender.com
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setBlogs(data); 
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {displayBlogs();}, []);


  return (
    
      <div className="blog-list-container"> 
     
    <div className="blog-list">
    {blogs.map((url, index) => (
          <li key={index}> 
            <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="read-more-btn"
          >
            {url}
          </a>
          </li>
        ))}
    </div>
  </div>
  );
};
 

 

export default BlogList;
