import { Link } from "react-router-dom";
import { FaGlobe, FaUser, FaNewspaper, FaSignInAlt } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import logo from './logo.png';





function CoolNav() {
 

  return (
    <div  style={{ display: 'flex', alignItems: 'center', padding: '1rem', backgroundColor: '#f5f5f5' }} >
      {/* Logo on the left */}
      <div style={{ marginRight: 'auto' }}>
        <Link to="/">
          <img src={logo} className="App-logo" alt="logo" style={{ height: '50px', cursor: 'pointer' }} />
        </Link>
        <p style={{ margin: 0, fontSize: '1.2rem', color: '#333', fontWeight: 'bold' }}>
          Entropy
        </p>
      </div>

      {/* Navigation links on the right */}
      <nav>
        <ul style={{
          listStyle: 'none',
          display: 'flex',
          gap: '1.5rem',
          margin: 0,
          padding: 0,
          alignItems: 'center',
        }}>
          
          
          <li>
            <Link to="/articles" style={navLinkStyle}>
              <FaNewspaper style={iconStyle} /> Articles
            </Link>
          </li>
          <li>
            <Link to="/profile" style={navLinkStyle}>
              <FaUser style={iconStyle} /> Profile
            </Link>
          </li>
          {/* <li>
            <Link to="/login" style={navLinkStyle}>
              <FaSignInAlt style={iconStyle} /> Login
            </Link>
          </li>
          <li>
            <Link to="/globe" onClick={(e) => { e.preventDefault(); onGlobeClick(); }} style={navLinkStyle}>
              <FaGlobe style={iconStyle} /> Globe
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
}

const navLinkStyle = {
  color: '#333',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.1rem',
  fontWeight: '500',
};

const iconStyle = {
  marginRight: '0.5rem',
  color: '#007bff',
};

export default CoolNav;
